export function formatDateToHumanFirendly(
  dateStr: string,
  timezone: string
): string {
  // Extract date part (YYYY-MM-DD)
  const [year, month, day] = dateStr.split("T")[0].split("-");

  // Convert month number to short month name
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const shortMonth = monthNames[parseInt(month, 10) - 1];

  // Get the day of the week (ignoring timezone)
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = weekdays[new Date(`${year}-${month}-${day}`).getUTCDay()];

  // Extract time part (HH:mm:ss)
  const timePart = dateStr.split("T")[1].split("-")[0]; // "12:00:00"

  // Convert 24-hour time to 12-hour format
  const [hour, minute] = timePart.split(":").map(Number);
  const period = hour >= 12 ? "PM" : "AM";
  const formattedHour = hour % 12 || 12; // Convert 0 to 12 for AM

  // Format final date and time
  const formattedDateTime = `${dayOfWeek}, ${shortMonth} ${parseInt(
    day,
    10
  )}, ${year}, ${formattedHour}:${minute
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedDateTime;
  // Output Example: "Wed, Apr 16, 2025, 12:00 PM"
}

export function convertToDateObjectWithTimezone(
  utcTime: string,
  timezone: string
): Date {
  const utcDate = new Date(utcTime);

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: timezone,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  const parts = formatter.formatToParts(utcDate);
  const year = parseInt(
    parts.find((part) => part.type === "year")?.value || "0",
    10
  );
  const month =
    parseInt(parts.find((part) => part.type === "month")?.value || "1", 10) - 1;
  const day = parseInt(
    parts.find((part) => part.type === "day")?.value || "1",
    10
  );
  const hour = parseInt(
    parts.find((part) => part.type === "hour")?.value || "0",
    10
  );
  const minute = parseInt(
    parts.find((part) => part.type === "minute")?.value || "0",
    10
  );
  const second = parseInt(
    parts.find((part) => part.type === "second")?.value || "0",
    10
  );
  return new Date(year, month, day, hour, minute, second);
}

export function convertTimeToTimezone(utcTime: any, timezone: any) {
  if (!utcTime) {
    console.error("convertTimeToTimezone: Received undefined or null utcTime.");
    return { time: "Invalid", period: "" };
  }

  if (!timezone || typeof timezone !== "string") {
    console.error(
      "convertTimeToTimezone: Received an invalid timezone:",
      timezone
    );
    timezone = "UTC"; // Defaulting to UTC as a fallback
  }

  console.log("UTC Time Input:", utcTime);
  console.log("Timezone Input:", timezone);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: timezone,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateObj = new Date(utcTime);
  if (isNaN(dateObj.getTime())) {
    console.error(
      "convertTimeToTimezone: Invalid Date Object created from",
      utcTime
    );
    return { time: "Invalid", period: "" };
  }

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedTime = formatter.format(dateObj);

  console.log("Formatted Time Output:", formattedTime);

  const [time, period] = formattedTime?.split(" ") || ["Invalid", ""];
  return { time, period };
}

export function formatTimestamp(isoString: any) {
  // Create a Date object from the ISO string
  const dateObj = new Date(isoString);

  // Extract date (year, month, day) and create a new Date object
  const formattedDate = new Date(
    dateObj.getUTCFullYear(),
    dateObj.getUTCMonth(),
    dateObj.getUTCDate()
  );

  // Extract time components
  let hours = dateObj.getUTCHours();
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour time to 12-hour format
  hours = hours % 12 || 12;

  // Return formatted values
  return {
    date: formattedDate, // Date object
    hours: hours.toString().padStart(2, "0"),
    minutes,
    period,
  };
}
export const validateTripDays = (
  startDate: null | Date,
  endDate: null | Date,
  selectedCar: any
): boolean => {
  if (!startDate || !endDate || !selectedCar) return false;

  const start = new Date(startDate);
  const end = new Date(endDate);

  // Ensure valid date objects
  if (isNaN(start.getTime()) || isNaN(end.getTime())) return false;

  const diffInDays = (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24);

  const minDays = selectedCar.min_trip_duration ?? 0; // Default to 0 if null/undefined
  const maxDays = selectedCar.max_trip_duration ?? Infinity; // Default to Infinity if null/undefined

  return diffInDays >= minDays && diffInDays <= maxDays;
};
