import { AxiosError } from "axios";
import customAxios from "config";

export const getInvoice = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      "/api/bookings/tentative_booking_invoice/",
      payload
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error: error.response?.data?.error,
      };
    }
    return { error: "An unknown error occurred." };
  }
};
