import customAxios from "config";
import { globalJson } from "global/global_json";
import toast from "react-hot-toast";

export const AddCustomer = async (payload: any): Promise<any> => {
  try {
    // Make the API call using customAxios
    const response = await customAxios.post<any>(
      "/api/company/add-website-customer/",
      payload
    );
    return { data: response.data };
  } catch (error) {
    console.error("AddCustomer API call failed:", error);
    return;
  }
};

export const getCustomer = async (email: any): Promise<any> => {
  try {
    const response = await customAxios.get(
      `/api/company/add-website-customer/?email=${email}&company_id=${
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id
      }`
    );
    return response.data;
  } catch (error) {
    console.error("AddCustomer API call failed:", error);
    return;
  }
};
