export const changeDate = (
  dates: [Date | null, Date | null],
  startHours: string,
  startMinutes: string,
  startPeriod: string,
  endHours: string,
  endMinutes: string,
  endPeriod: string,
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>,
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>
) => {
  const [start, end] = dates;

  if (!start) return; 
  const newStart = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  let startHour = parseInt(startHours);
  if (startPeriod === "PM" && startHour !== 12) startHour += 12;
  if (startPeriod === "AM" && startHour === 12) startHour = 0;
  newStart.setHours(startHour, parseInt(startMinutes), 0, 0);

  let newEnd: Date | null = null;

  if (end) {
    newEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate());
    let endHour = parseInt(endHours);
    if (endPeriod === "PM" && endHour !== 12) endHour += 12;
    if (endPeriod === "AM" && endHour === 12) endHour = 0;
    newEnd.setHours(endHour, parseInt(endMinutes), 0, 0);
  }
  setStartDate(newStart);
  setEndDate(newEnd);

};
