import { AxiosError } from "axios";
import customAxios from "config";
import toast from "react-hot-toast";

export const Booking = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>("/api/bookings/", payload);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.error || "An error occurred during booking.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const cancelBooking = async (
  booking_id: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      `/api/bookings/${booking_id}/cancel/`
    );
    return { data: response.data }; // Return data when successful
  } catch (error: any) {
    if (error.response && error.response.data && error.response.data.error) {
      // If the backend returns an error message in the response, use that
      return { error: error.response.data.error };
    } else if (error instanceof AxiosError) {
      return { error: error?.response?.data.detail || "A network error occurred." };
    }
    return { error: "An unknown error occurred." }; // Fallback for other unexpected errors
  }
};



export const getBooking = async (): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get<any>("/api/bookings/");

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during fetcing booking.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const retriveBooking = async (
  bookingId: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get<any>(`/api/bookings/${bookingId}/`);

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail || "An error occurred during booking.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const getTripPhotos = async (
  bookingId: string
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.get<any>(
      `/api/fleet/upload-fleet-images/?booking_id=${bookingId}`
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during getting photos.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const uploadTripPhotos = async (
  files: File[],
  bookingId: string,
  preTrip: boolean
) => {
  const formData = new FormData();

  // Append each file to the form data
  for (let i = 0; i < files.length; i++) {
    formData.append("images", files[i]); // The key 'images' will match the backend expected form field name
  }

  // Append booking ID and image type
  formData.append("booking_id", bookingId); // Append the booking ID
  formData.append("image_type", preTrip ? "pre_trip" : "post_trip"); // Append image type based on preTrip flag

  try {
    const response = await customAxios.post(
      `/api/fleet/upload-fleet-images/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Important for sending form data
        },
      }
    );

    // Assuming the response will contain the uploaded photo details
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during photo upload.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const deleteTripPhoto = async (id: number) => {
  try {
    const response = await customAxios.delete(
      `/api/fleet/upload-fleet-images/${id}/`
    );
    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.detail ||
          "An error occurred during deleting photos.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};

export const tripModificationRequest = async (
  payload: any
): Promise<{ data?: any; error?: string }> => {
  try {
    const response = await customAxios.post<any>(
      "/api/bookings/req_modification/",
      payload
    );

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        error:
          error.response?.data?.error || "An error occurred during booking.",
      };
    }
    return { error: "An unknown error occurred." };
  }
};
